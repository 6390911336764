<template>
  <div>
    <CCard
      v-if="getSafe(() => buyerAccountDetails.testOnly)"
      class="test-account-banner bg-danger p-3 text-white"
    >
      <strong>
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        This is a test-only account
      </strong>
    </CCard>

    <CCard
      v-if="getSafe(() => isAdminApprovalRequirementNotMet)"
      class="test-account-banner bg-warning px-3 py-3 text-white mb-3"
      :class="{
        'test-banner--position-2': getSafe(() => buyerAccountDetails.testOnly)
      }"
    >
      <strong class="d-flex align-items-center">
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        Admin Approval is pending
        <CButton
          color="success"
          class="ml-auto"
          size="sm"
          @click="warningApproveModal = true"
        >
          Approve
        </CButton>
      </strong>
    </CCard>

    <CCard>
      <CCardHeader>
        <div class="d-flex justify-content-between">
          <div>Buyer Account Details</div>

          <div>
            <CButton
              size="sm"
              :color="editMode ? 'danger' : 'primary'"
              class="mr-2"
              @click="toggleEditMode"
            >
              {{ editMode ? 'Cancel' : 'Edit Details' }}
            </CButton>
          </div>
        </div>
      </CCardHeader>

      <CCardBody>
        <div v-if="buyerAccountDetails && buyerAccountDetails.id">
          <div>
            <div v-if="!editMode">
              <AccountDetailsCommonInfoReadOnly
                type="buyer"
                :account-details="buyerAccountDetails"
              />
            </div>
            <div v-else>
              <AccountDetailsCommonInfoEdit
                type="buyer"
                :account-details="buyerAccountDetails"
                @updated="toggleEditMode"
              />
            </div>
          </div>
          <div v-if="!editMode">
            <h3>
              Other Details
            </h3>
            <dl class="row">
              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Time Zone
              </dt>
              <dd
                class="col-sm-9"
              >
                {{ getSafe(() => timezonesMap[buyerAccountDetails.timeZoneId].label) }}
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                No. of Employees
              </dt>
              <dd
                class="col-sm-9"
              >
                {{ buyerAccountDetails.buyerRegistrationDetails.numberOfEmployeesRange }}
              </dd>
              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Call Purchase Volume
              </dt>
              <dd
                class="col-sm-9"
              >
                {{ buyerAccountDetails.buyerRegistrationDetails.callPurchaseVolumeRange }}
              </dd>
            </dl>
          </div>
        </div>

        <SignedDocumentBundles
          account-type="buyer"
          :account-id="getSafe(() => buyerAccountDetails.id)"
          :signed-bundles="getSafe(() => signedBundles)"
        />

        <AccountRequirements
          class="mt-4"
          :account-requirements="buyerAccountRequirements"
        />
      </CCardBody>
    </CCard>

    <AccountBillingDetails
      v-if="buyerAccountDetails"
      :account-billing-config="buyerAccountDetails.billingConfiguration"
      :account-id="accountId"
    />

    <AccountTrafficDetails
      v-if="buyerAccountDetails"
      :account="buyerAccountDetails"
      type="buyer"
    />

    <BuyerAccountBudgetDetails 
      v-if="buyerAccountDetails"
      :account-id="buyerAccountDetails.id"
      :buyer-account-budget="buyerAccountDetails.budget"
    />

    <BuyerRtbSettings 
      v-if="buyerAccountDetails"
      :account-id="buyerAccountDetails.id"
      :rtb-settings="buyerAccountDetails.rtbSettings"
    />

    <ReviewNotes
      v-if="buyerAccountDetails"
      :parent-object="buyerAccountDetails"
    />

    <AccountUsers 
      v-if="buyerAccountDetails"
      :account-id="buyerAccountDetails.id"
      account-type="Buyer"
      :account-name="buyerAccountDetails.name"
    />

    <div>
      <BuyerCampaignsList
        :buyer-campaigns="buyerCampaigns"
        :is-loading="isLoading"
        :prefiltered-by-account="true"        
      />
    </div>

    <div>
      <BuyerBlockedChannels
        v-if="buyerAccountDetails"
        :account-id="buyerAccountDetails.id"     
      />
    </div>

    <RegistrationRequestChecklist
      v-if="buyerAccountDetails && buyerAccountDetails.registrationId"
      :registration-request-id="buyerAccountDetails.registrationId"
      type="buyer"
    />

    <div>
      <CButton 
        v-if="isAdminApprovalRequirementNotMet"
        type="submit"
        size="lg"
        color="success"
        class="mb-3"
        @click="warningApproveModal = true"
      >
        Final Approve 
      </CButton>
    </div>

    <CModal
      v-if="warningApproveModal"
      title="Confirm final approve"
      color="success"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this buyer?

      <p class="mt-3">
        <CRow
          class="form-group form-row"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Account Type
          </CCol>
          <CCol>
            <div class="d-flex align-items-center mb-3">
              <CSwitch
                variant="3d"
                color="primary"
                horizontal
                size="sm"
                class="mr-2"
                :checked.sync="buyerAccountDetails.billingConfiguration.prePay"
              />
              <div class="m-0">
                {{ buyerAccountDetails.billingConfiguration.prePay ? 'Pre-Pay' : 'Post-Pay' }} 
              </div>
              <CIcon 
                v-c-tooltip="'Pre Pay: pay upfront with credit card. Post Pay: use credit line, pay via invoice according to the payment terms'" 
                name="cil-lightbulb" 
                size="sm"
              />
            </div>
          </CCol>
        </CRow>
      </p>

      <p class="alert alert-warning mt-2">
        <strong>Note:</strong>
        The account will be active after the final approval has been granted, so that a buyer can create campaigns and purchase calls.
      </p>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="approveAccount"
        >
          Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import isEmpty from 'lodash.isempty'

import AccountBillingDetails from './AccountBillingDetails.vue'
import AccountTrafficDetails from './AccountTrafficDetails.vue'
import BuyerAccountBudgetDetails from './BuyerAccountBudgetDetails.vue'
import SignedDocumentBundles from './SignedDocumentBundles.vue'
import AccountDetailsCommonInfoReadOnly from './AccountDetailsCommonInfoReadOnly.vue'
import AccountDetailsCommonInfoEdit from './AccountDetailsCommonInfoEdit.vue'
import AccountRequirements from './AccountRequirements.vue'
import AccountUsers from '@/views/Users/AccountUsers.vue'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import RegistrationRequestChecklist from '@/views/RegistrationRequests/RegistrationRequestChecklist.vue'
import BuyerBlockedChannels from './BuyerBlockedChannels.vue'
import { showSuccessMessage } from '@/notification-utils'
import { COMMON_ACCOUNT_REQUIREMENTS } from '@/store/modules/accounts.store'
import BuyerRtbSettings from './BuyerRtbSettings.vue'
import BuyerCampaignsList from '../Campaigns/buyer/BuyerCampaignsList.vue'

export default {
  name: 'BuyerAccountDetails',

  components: {
    ReviewNotes,
    AccountBillingDetails,
    AccountTrafficDetails,
    SignedDocumentBundles,
    AccountDetailsCommonInfoReadOnly,
    AccountDetailsCommonInfoEdit,
    AccountRequirements,
    BuyerAccountBudgetDetails,
    AccountUsers,
    RegistrationRequestChecklist,
    BuyerRtbSettings,
    BuyerCampaignsList,
    BuyerBlockedChannels
  },

  data() {
    return  {
      editMode: false,
      warningApproveModal: false,
      isLoading: false,
      COMMON_ACCOUNT_REQUIREMENTS
    }
  },

  computed: {
    ...mapState('accounts', [
      'buyerAccountDetails',
      'buyerAccountRequirements'
    ]),

    ...mapState('category', [
      'categories'
    ]),

    ...mapState('trafficSource', [
      'trafficSources'
    ]),

    ...mapState('campaign', ['buyerCampaigns']),

    ...mapGetters('timezones', [
      'timezonesMap'
    ]),

    categoriesMap() {
      return this.categories.reduce(function(map, obj) {
          map[obj.id] = obj
          return map
      }, {})
    },

    trafficSourcesMap() {
      return this.trafficSources.reduce(function(map, obj) {
          map[obj.id] = obj
          return map
      }, {})
    },

    signedBundles() {
      return this.buyerAccountDetails?.eSignature?.signedBundles ?? this.buyerAccountDetails?.eSignature?.signedBundleIds?.map(b => ({ id: b }))
    },

    isAdminApprovalRequirementNotMet() {
      return !isEmpty(this.buyerAccountRequirements?.requirements) && !this.buyerAccountRequirements?.requirements[COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT]
    }
  },

  async created() {
    this.accountId = this.$route.params.id

    if (this.accountId) {
      try {
        await this.getBuyerAccountDetails(this.accountId)
        await this.getBuyerAccountRequirements(this.accountId)
        document.title = this.buyerAccountDetails?.name
      } catch (e) {
        //
      }
    }

    await this.getCategories()
    await this.getTrafficSources()
    await this.getCampaigns()

    this.SET_CURRENT_REGISTRATION_TYPE('buyer')
  },

  methods: {
    ...mapActions('accounts', [
      'getBuyerAccountDetails',
      'getBuyerAccountRequirements',
      'markAccountAdminApprovalRequirement',
    ]),

    ...mapActions('category', {
      getCategories: 'list'
    }),

    ...mapActions('trafficSource', {
      getTrafficSources: 'list'
    }),

    ...mapActions('campaign', ['fetchBuyerCampaigns']),

    ...mapMutations('registrationRequests', [
      'SET_CURRENT_REGISTRATION_TYPE'
    ]),

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    async approveAccount() {
      try {
        this.isLoading = true

        await this.markAccountAdminApprovalRequirement({ 
          accountId: this.buyerAccountDetails.id, 
          approve: true,
          accountType: 'buyer'
        })

        showSuccessMessage('Admin Approval done successfully')

        this.warningApproveModal = false

        await this.getBuyerAccountRequirements(this.accountId)
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async getCampaigns() {
      try {
        this.isLoading = true
        await this.fetchBuyerCampaigns({ detailed: true, accountId: this.accountId })
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
  }

}
</script>

<style scoped lang="scss">

</style>