<template>
  <CCard>
    <CCardHeader>
      Edit Account Billing
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <dl class="row">
          <dt class="col-sm-3 font-weight-normal">
            Current Balance
          </dt>
          <dd class="col-sm-9 d-flex align-items-center mb-0">
            <h6 class="mr-3">
              {{ getSafe(() => formatAmount(accountBalance.cashBalance)) }}
            </h6>

            <router-link
              v-if="isFinancialManager || isSuperAdmin"
              :to="{ name: 'BuyerAccountAddFunds', params: { accountId: accountId} }"
            >
              <CButton
                size="sm"
                color="success"
                style="position: relative; bottom: 3px;"
              >
                Add Funds
              </CButton>
            </router-link>
          </dd>
        </dl>

        <hr>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            Is Pre Pay Buyer? 
            <CIcon 
              v-c-tooltip="'Pre Pay: pay upfront with credit card. Post Pay: use credit line, pay via invoice according to the payment terms'" 
              name="cil-lightbulb" 
              size="custom"
            />
          </label>

          <CSwitch
            label-off="No"
            label-on="Yes"
            shape="pill"
            color="primary"
            data-cy="pre-pay-checkbox"
            horizontal
            add-label-classes=""
            add-input-classes="w-25"
            size="lg"
            :checked.sync="accountBillingConfigLocal.prePay"
          />
        </div>

        <CInput
          v-if="accountBillingConfigLocal.prePay"
          v-model="$v.accountBillingConfigLocal.minimumInitialDeposit.$model"
          type="number"
          label="Minimum Initial Deposit"
          name="minimumInitialDeposit"
          placeholder="Minimum Initial Deposit"
          :is-valid="checkIfValid('minimumInitialDeposit')"
          horizontal
          :invalid-feedback="minimumInitialDepositInvalid"
        />       

        <CInput
          v-model="$v.accountBillingConfigLocal.minimumBalance.$model"
          type="number"
          label="Minimum Balance"
          name="minimumBalance"
          placeholder="Balance"
          :is-valid="checkIfValid('minimumBalance')"
          horizontal
          :invalid-feedback="minimumBalanceInvalid"
        />

        <CInput
          v-if="accountBillingConfigLocal.prePay"
          v-model="$v.accountBillingConfigLocal.minimumCreditCardChargeAmount.$model"
          name="minimumCreditCardChargeAmount"
          type="number"
          label="Minimum Credit Card Charge Amount"
          placeholder="Minimum Credit Card Charge Amount"
          horizontal
          :is-valid="checkIfValid('minimumCreditCardChargeAmount')"
          :invalid-feedback="minimumCreditCardChargeInvalid"
        />

        <CInput
          v-if="accountBillingConfigLocal.prePay"
          v-model="$v.accountBillingConfigLocal.maximumCreditCardChargeAmount.$model"
          type="number"
          label="Maximum Credit Card Charge Amount"
          name="maximumCreditCardChargeAmount"
          placeholder="Maximum Credit Card Charge Amount"
          :is-valid="checkIfValid('maximumCreditCardChargeAmount')"
          horizontal
          :invalid-feedback="maximumCreditCardChargeInvalid"
        />

        <div
          v-if="accountBillingConfigLocal.prePay"
          class="form-group form-row"
        >
          <label class="col-form-label col-sm-3">
            Auto Recharge
          </label>

          <CInputCheckbox
            data-cy="auto-recharge-checkbox"
            label="Enable"
            :checked.sync="accountBillingConfigLocal.autoRecharge"
            horizontal
            class="mt-2 ml-1"
          />
        </div>        

        <CInput
          v-if="accountBillingConfigLocal.prePay"
          v-model="$v.accountBillingConfigLocal.rechargeBalance.$model"
          type="number"
          label="Recharge Balance"
          name="rechargeBalance"
          placeholder="Balance"
          :is-valid="checkIfValid('rechargeBalance')"
          :readonly="!accountBillingConfigLocal.autoRecharge"
          horizontal
          :invalid-feedback="rechargeBalanceInvalid"
        />

        <CInput
          v-if="accountBillingConfigLocal.prePay"
          v-model="$v.accountBillingConfigLocal.rechargeAmount.$model"
          name="rechargeAmount"
          type="number"
          label="Recharge Amount"
          placeholder="Recharge Amount"
          horizontal
          :is-valid="checkIfValid('rechargeAmount')"
          :invalid-feedback="autoRechargeInvalid"
        />

        <CSelect
          v-if="!accountBillingConfigLocal.prePay"
          :value.sync="$v.accountBillingConfigLocal.billingCycle.$model"
          label="Billing Period"
          horizontal
          :options="billingCycles"
          placeholder="Please select"
          :is-valid="checkIfValid('billingCycle')"
          :invalid-feedback="billingCycleInvalid"
        />

        <CInput
          v-if="!accountBillingConfigLocal.prePay"
          :value.sync="accountBillingConfigLocal.paymentTermsInDays"
          type="number"
          label="Payment Terms"
          name="paymentTerms"
          placeholder="Payment terms (in days)"
          horizontal
          :is-valid="checkIfValid('paymentTermsInDays')"
          :invalid-feedback="paymentTermsInDaysInvalid"
        />

        <div
          v-if="false"
          class="form-group form-row"
        >
          <label class="col-form-label col-sm-3">
            Custom Dispute Fees
          </label>

          <div>
            <CSwitch
              label-off="No"
              label-on="Yes"
              shape="pill"
              color="primary"
              data-cy="pre-pay-checkbox"
              horizontal
              add-label-classes=""
              add-input-classes="w-25"
              size="lg"
              :checked.sync="accountBillingConfigLocal.setCustomDisputeFees"
              @update:checked="onChangeDisputeFeesSetting"
            />
            <div class="text-muted">
              Standard Dispute Fees of <strong>{{ formatAmount(globalBuyerDisputeFees) }}</strong> applies
            </div>
          </div>
        </div>

        <CInput
          v-if="accountBillingConfigLocal.setCustomDisputeFees"
          :value.sync="$v.accountBillingConfigLocal.disputeFees.$model"
          type="number"
          label="Dispute Fees"
          name="Dispute Fees"
          placeholder="Enter fees"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-4 col-12'}"
          :is-valid="checkIfValid('disputeFees')"
          invalid-feedback="Please enter dispute fees greater than $0"
        />

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <CButton
              type="submit"
              size="sm"
              color="primary"
              data-cy="submit-billing"
              @click="onFormSubmit"
            >
              Change
            </CButton>
          </CCol>
        </div>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm change billing"
      color="success"
      data-cy="change-billing-modal"
      :show.sync="warningBillingChangeModal"
    >
      Are you sure you want to edit the billing configuration?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="onConfirmSubmit"
        >
          Change
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningBillingChangeModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { minValue, required, maxValue  } from 'vuelidate/lib/validators'
import { formatAmount } from '@/utils'

import { showSuccessMessage } from '@/notification-utils'
import {
  MIN_INITIAL_DEPOSIT,
  MIN_AUTO_RECHARGE,
  MIN_CREDIT_CARD_CHARGE_AMOUNT,
  MAX_CREDIT_CARD_CHARGE_AMOUNT,
  MIN_RECHARGE_BALANCE
} from '@/store/modules/registration-requests.store'
import {
  MIN_BUYER_ACCOUNT_BALANCE_RANGE,
  ACCOUNT_BILLING_DEFAULTS
} from '@/store/modules/accounts.store'

export default {
  name: 'AccountBillingDetails',

  props: {
    accountBillingConfig: {
      type: Object,
      default() {
        return {}
      }
    },

    accountId: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
      accountBillingConfigLocal: {
        minimumInitialDeposit: {
          minValue: minValue(MIN_INITIAL_DEPOSIT),
          required
        },

        minimumBalance: {
          minValue: minValue(MIN_BUYER_ACCOUNT_BALANCE_RANGE.min),
          maxValue: maxValue(MIN_BUYER_ACCOUNT_BALANCE_RANGE.max),
          required
        },

        minimumCreditCardChargeAmount: {
          minValue: minValue(MIN_CREDIT_CARD_CHARGE_AMOUNT),
          required
        },

        maximumCreditCardChargeAmount: {
          minValue: minValue(MIN_CREDIT_CARD_CHARGE_AMOUNT),
          maxValue: maxValue(MAX_CREDIT_CARD_CHARGE_AMOUNT),
          required
        },

        rechargeBalance: {
          minValue: this.accountBillingConfigLocal.autoRecharge ? minValue(MIN_RECHARGE_BALANCE) : 0,
          required
        },

        rechargeAmount: {
          minValue: minValue(MIN_AUTO_RECHARGE),
          required
        },

        billingCycle: {
          required
        },

        paymentTermsInDays: {
          minValue: 14,
          required
        },

        disputeFees: {
          minValue: minValue(0)
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,

      warningBillingChangeModal: false,

      accountBillingConfigLocal: {
        rechargeBalance: 0,
        rechargeAmount: 0,
        minimumInitialDeposit: 0,
        minimumBalance: 0,
        minimumCreditCardChargeAmount: 0,
        maximumCreditCardChargeAmount: 0,
        prePay: true,
        billingCycle: 0,
        paymentTermsInDays: ACCOUNT_BILLING_DEFAULTS.paymentTermsInDays,
        disputeFees: null
      },

      setCustomDisputeFees: false,
      minimumInitialDepositInvalid: `Please enter a valid value, it should not be below $${MIN_INITIAL_DEPOSIT}`,
      minimumCreditCardChargeInvalid: `Please enter a valid value, it should not be below $${MIN_CREDIT_CARD_CHARGE_AMOUNT}`,
      maximumCreditCardChargeInvalid: `Please enter a valid value, it should not be below $${MIN_CREDIT_CARD_CHARGE_AMOUNT} and above $${MAX_CREDIT_CARD_CHARGE_AMOUNT}`,      
      autoRechargeInvalid: `Please enter a valid value, it should not be below $${MIN_AUTO_RECHARGE}`,
      rechargeBalanceInvalid: `Please enter a valid value, it should not be below $${MIN_RECHARGE_BALANCE}`,
      paymentTermsInDaysInvalid: `Please enter a valid value, it should be between 14 and 90`,
      billingCycleInvalid: `Please select a valid value from the list`,
      minimumBalanceInvalid: `Please enter a valid value, it should be between ${formatAmount(MIN_BUYER_ACCOUNT_BALANCE_RANGE.min)} - ${formatAmount(MIN_BUYER_ACCOUNT_BALANCE_RANGE.max)}`
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isFinancialManager',
      'isSuperAdmin'
    ]),

    ...mapState('accounts', {
      accountBalance: 'accountBalance',
      globalBuyerDisputeFees: 'globalBuyerDisputeFees'
    }),

    ...mapState(['billingCycles']),

    isValid() {
      return !this.$v.$invalid
    }
  },

  watch: {
    accountBillingConfig: {
      immediate: true,
      handler(newValue) {
        this.accountBillingConfigLocal = newValue
          ? { ...newValue, setCustomDisputeFees: newValue?.disputeFees != null }
          : {
              rechargeBalance: 0,
              rechargeAmount: 0,
              minimumInitialDeposit: 0,
              minimumBalance: 0,
              minimumCreditCardChargeAmount: 0,
              maximumCreditCardChargeAmount: 0,
              billingCycle: 0,
              paymentTermsInDays: ACCOUNT_BILLING_DEFAULTS.paymentTermsInDays,
              setCustomDisputeFees: false
            }
      }
    }
  },

  async created () {
    await this.getGlobalBuyerDisputeFees()

    await this.getAccountBalance(this.accountId)

    this.accountBillingConfigLocal.setCustomDisputeFees = this.accountBillingConfigLocal?.disputeFees != null
  },

  methods: {
    ...mapActions('accounts', [
      'updateBuyerAccountBillingConfiguration', 
      'getGlobalBuyerDisputeFees'
    ]),

    ...mapActions('accounts', [
      'getAccountBalance',
    ]),

    onFormSubmit() {
      this.$v.accountBillingConfigLocal.$touch()

      if (this.isValid) {
        this.warningBillingChangeModal = true
      }
    },

    async onConfirmSubmit() {
      if (this.isValid) {
        this.isLoading = true

        try {
          await this.updateBuyerAccountBillingConfiguration({
            accountId: this.accountId,
            billingConfig: this.accountBillingConfigLocal
          })

          this.warningBillingChangeModal = false
          
          showSuccessMessage('Billing configuration updated successfully')
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v.accountBillingConfigLocal[fieldName]

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    formatAmount,

    onChangeDisputeFeesSetting() {
      if (this.accountBillingConfigLocal.setCustomDisputeFees) {
        this.accountBillingConfigLocal.disputeFees = this.globalBuyerDisputeFees
      } else {
        this.accountBillingConfigLocal.disputeFees = null
      }
    }
    
  }
}
</script>

<style></style>
