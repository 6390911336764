<template>    
  <CCard>
    <CCardHeader>
      <slot name="header">        
        <CIcon
          name="cil-basket"
          class="mr-1"
        /> {{ pageTitle }}
      </slot>      
    </CCardHeader>

    <CModal
      v-if="selectedCampaign"
      :title="pauseSelectedCampaign ? 'Pause Campaign' : 'Resume Campaign'"
      :color="pauseSelectedCampaign ? 'warning' : 'success'"
      :show.sync="showPauseOrResumeConfirmationModal"
    >
      Are you sure you want to {{ pauseSelectedCampaign ? 'pause' : 'resume' }} <strong><i>{{ selectedCampaign.name }}</i></strong> buyer campaign?

      <CTextarea
        v-model="note"
        class="mt-2"
        name="Note"
        label="Note"
        :is-valid="isNoteValid"
        invalid-feedback="Note is mandatory"
        small
        required
        rows="3"
        :debounce="500"
      />

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isPauseLoading"
          :disabled="!isNoteValid"
          @click="onConfirmPauseOrResume"
        >
          {{ pauseSelectedCampaign ? 'Pause' : 'Resume' }}
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showPauseOrResumeConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal> 
    <CCardBody>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            :checked.sync="includeTestOnly"
            class="mr-2"
          />
          <h5 class="m-0">
            Include Test Only Campaigns
          </h5>
        </div>
        <CDataTable
          data-cy="table-buyer-campaigns"
          small
          border
          sorter
          column-filter
          :items="campaignsFilteredAndFormatted"
          :fields="fields"
          :items-per-page="10"
          :sorter-value="{ column: 'creationDate', asc: false }"
          :pagination="{align: 'center'}"
        >
          <template #id="{item}">
            <td class="text-right">
              <div class="d-flex align-items-center">
                <div
                  class="text-truncate w-fixed-70 mr-2"
                >
                  {{ item.id }}
                </div>

                <a
                  href
                  @click.prevent="copyIdToClipboard(item.id)"
                ><CIcon
                  name="cil-copy"
                /></a>
              </div>
            </td>
          </template>

          <template #name="{item}">
            <td>
              {{
                item.name
              }}
              <CBadge
                v-if="item.category.testOnly"
                color="danger"
                class="ml-1"
              >
                Test
              </CBadge>
            </td>
          </template>
          
          <template #status="{item}">
            <td>
              <CBadge :color="getStatusBadge(item)">
                {{ item.status }}
              </CBadge>
            </td>
          </template>

          <template #creationDate="{item}">
            <td>
              {{ item.formattedCreationDate }}
            </td>
          </template>

          <template #lastPausedResumedDate="{item}">
            <td>
              {{ item.formattedLastPausedResumedDate }}
            </td>
          </template>

          <template #Actions="{item}">
            <td>
              <CButton
                color="info"
                size="sm"
                class="mt-1 ml-2"
                :to="`/dashboard/campaigns/buyer/${item.id}`"
              >
                View Campaign
              </CButton>

              <CButton
                v-if="!canCampaignBeResumed(item)"
                color="warning"
                size="sm"
                class="ml-2 mt-1"
                @click="showConfirmationForPauseOrResume(item, true)"
              >
                Pause
              </CButton>

              <CButton
                v-if="canCampaignBeResumed(item)"
                color="success"
                size="sm"
                class="ml-2 mt-1"
                @click="showConfirmationForPauseOrResume(item, false)"
              >
                Resume
              </CButton>
            </td>
          </template>
        </CDataTable>
      </div>
    </CCardBody>   
  </CCard>
</template>

<script>
import { mapActions } from 'vuex'

import buyerCampaignStatusMixin from '@/views/Campaigns/buyer/mixins/buyer-campaign-status-mixin'
import { dateTimeFormat } from '@/utils'
import { showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'

export default {
  name: 'BuyerCampaignsList',

  mixins: [buyerCampaignStatusMixin, copyToClipboardMixin],

  props: {
    pageTitle: {
      type: String,
      default: 'Buyer Campaigns'
    },

    buyerCampaigns: {
      type: Array,
      default() {
        return []
      }
    },

    prefilteredByAccount: {
      type: Boolean,
      default: false
    },
    
    isLoading: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showJSONModal: false,
      selectedCampaign: {},
      showPauseOrResumeConfirmationModal: false,
      note: '',
      pauseSelectedCampaign: false,
      isPauseLoading: false,
      fields: this.prefilteredByAccount ? Object.freeze([
        { key: 'status', label: 'Status', sorter: true },
        { key: 'lastPausedResumedDate', label: 'Last Pause/Resume At',  },
        { key: 'lastPausedResumedBy', label: 'Last Pause/Resume By',  },
        { key: 'id', label: 'Id',  },
        { key: 'name', label: 'Name',  },
        { key: 'categoryName', label: 'Category', sorter: false },
        { key: 'languageName', label: 'Language',  },
        { key: 'creationDate', label: 'Created At',  },
        { key:'Actions', filter: false, sorter: false },
      ]) : Object.freeze([
        { key: 'status', label: 'Status', },
        { key: 'lastPausedResumedDate', label: 'Last Pause/Resume At',  },
        { key: 'lastPausedResumedBy', label: 'Last Pause/Resume By',  },
        { key: 'id', label: 'Id',  },
        { key: 'name', label: 'Name',  },
        { key: 'categoryName', label: 'Category', sorter: false },
        { key: 'accountName', label: 'Account' },
        { key: 'languageName', label: 'Language',  },
        { key: 'creationDate', label: 'Created At',  },
        { key:'Actions', filter: false, sorter: false },
      ]),

      includeTestOnly: false
    }
  },

  computed: {
    campaignsFilteredAndFormatted() {
      return this.buyerCampaigns
        .filter(i => this.includeTestOnly ? true : !i.category?.testOnly)
        .map(campaign => {
          return {
            ...campaign,
            formattedCreationDate: dateTimeFormat.format(new Date(campaign.creationDate)),
            formattedLastPausedResumedDate: campaign.lastPausedResumedDate != null ? dateTimeFormat.format(new Date(campaign.lastPausedResumedDate)) : '',
            lastPausedResumedBy: campaign.lastPausedResumedBy?.name ?? campaign.lastPausedResumedBy?.id ?? '',
            languageName: campaign.language?.name ?? '',
            categoryName: `${campaign.category?.parentCategory?.name} / ${campaign.category?.name}`,
            accountName: campaign.account?.name ?? '',
            status: campaign.isPaused ? 'Paused' : campaign.status
          }
        })
    },

    isNoteValid() {
      return !!this.note.length
    },

  },

  methods: {
    ...mapActions('campaign', [
      'resumeBuyerCampaign',
      'pauseBuyerCampaign'
    ]),

    openViewJSONModal(campaign) {
      this.selectedCampaign = campaign
      this.showJSONModal = true
    },

    showConfirmationForPauseOrResume(campaign, pause) {
      this.note = ''
      this.showPauseOrResumeConfirmationModal = true
      this.selectedCampaign = campaign
      this.pauseSelectedCampaign = pause
    },

    async onConfirmPauseOrResume() {
      try {
        this.isPauseLoading = true

        if (this.pauseSelectedCampaign) {
          await this.pauseBuyerCampaign({ id: this.selectedCampaign.id, remarks: this.note })

          showSuccessMessage('Paused buyer campaign')
        } else {
          await this.resumeBuyerCampaign({ id: this.selectedCampaign.id, remarks: this.note, status: this.selectedCampaign.status })

          showSuccessMessage('Resumed buyer campaign')
        }

        this.showPauseOrResumeConfirmationModal = false
        this.note = ''
      } catch {
        // 
      } finally {
        this.isPauseLoading = false
        this.selectedCampaign = null
      }
    },    

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Campaign Id copied to clipboard')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>