var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-basket"}}),_vm._v(" Creatives ")])],2),_c('CCardBody',[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('CSwitch',{staticClass:"mr-2",attrs:{"variant":"3d","color":"primary","horizontal":"","size":"sm","checked":_vm.showRejected},on:{"update:checked":[function($event){_vm.showRejected=$event},_vm.loadCreatives]}}),_c('h5',{staticClass:"m-0"},[_vm._v(" Show Rejected ")])],1),_c('CDataTable',{attrs:{"small":"","border":"","sorter":"","hover":"","column-filter":"","items":_vm.formattedReviewedCreatives,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" "),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyIdToClipboard(_vm.getSafe(function () { return item.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)])]}},{key:"account",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'SellerAccountDetails', params: { id: _vm.getSafe(function () { return item.account.id; })} },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.account.name; }))+" ")]),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyAccountIdToClipboard(_vm.getSafe(function () { return item.account.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.category.name; }))+" ")])]}},{key:"trafficSource",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.trafficSource.name; }))+" ")])]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.country.id; }))+" ")])]}},{key:"language",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.language.id; }))+" ")])]}},{key:"stats",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.stats)?_c('div',{staticClass:"w-fixed-130"},[_c('div',[_vm._v("Total: "+_vm._s(_vm.getSafe(function () { return item.stats.total; })))]),_c('div',[_vm._v("Pending: "+_vm._s(_vm.getSafe(function () { return item.stats.pending; })))]),_c('div',[_vm._v("Approved: "+_vm._s(_vm.getSafe(function () { return item.stats.approved; })))]),_c('div',[_vm._v("Rejected: "+_vm._s(_vm.getSafe(function () { return item.stats.rejected; })))])]):_vm._e()])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(_vm.getSafe(function () { return item.status; }))+" "),_c('small',[_vm._v(_vm._s(_vm.getSafe(function () { return item.enabled ? 'Enabled' : 'Disabled'; } )))])])])]}},{key:"creationDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.formattedCreationDate)+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":("/dashboard/creatives/" + (item.id))}},[_vm._v(" View Creative ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }